<div class="container">
  <div class="page-container">
    <div class="devices-text">
      <div class="devices-logo">
        <span class="temp-logo__main"><img src="assets/images/svg/heating_logo.svg"></span>
      </div>
      <div>To connect your Firefly,  <br />click the "scan devices" button below, press both touch sensors on your Firefly, then find your device in the browser modal window and click "Pair".</div>
    </div>
    <button (click)="scanDevices()" class="gradient-btn">Scan devices</button>
  </div>

</div>

