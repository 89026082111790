import {Component, OnDestroy, OnInit} from '@angular/core';
import {BleInteractionService} from '../../shared/services/ble-interaction.service';
import {TEMPERATURE_ENUM, TEMPERATURE_LEVELS} from '../../constants/temperature';
import {Subscription} from 'rxjs';
import {TemperatureScaleService} from '../../shared/services/temperature-scale.service';

@Component({
  selector: 'app-temperature',
  templateUrl: './temperature.component.html',
  styleUrls: ['./temperature.component.scss']
})
export class TemperatureComponent implements OnInit, OnDestroy {
  public currentTemp: number;
  public tempType: string;
  public minTemp: number;
  public maxTemp: number;
  public valueStep: 5 | 10;
  public logoHeating: number;
  public backHeating: number;
  public tempLevel: string;
  private subscriptions: Subscription = new Subscription();

  constructor(private bleInteractionService: BleInteractionService, private tempScaleService: TemperatureScaleService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.tempScaleService.tempScale.subscribe(res => {
      this.tempType = res;
      this.valueStep = this.tempType === 'f' ? 10 : 5;
      this.minTemp = TEMPERATURE_ENUM[46][this.tempType];
      this.maxTemp = TEMPERATURE_ENUM[16][this.tempType];
      this.getTemperatureFromDevice();
    }));
  }

  public getTemperatureFromDevice(): void {
    this.subscriptions.add(this.bleInteractionService.deviceTempByte.subscribe(res => {
      if (res) {
        this.currentTemp = TEMPERATURE_ENUM[res][this.tempType];
        this.tempChanged();
      }
    }));
  }

  public tempChanged(): void {
    const valDiff = this.maxTemp - this.minTemp;
    const heatLevel = this.currentTemp - this.minTemp;
    const heatRangePercents = heatLevel / (valDiff / 100);
    this.backHeating = (100 - heatRangePercents) / 2;
    this.logoHeating = heatRangePercents / 100 / 2 + 0.5;
    this.getTempLevel();
  }

  public setNewTemperature(): void {
    Object.keys(TEMPERATURE_ENUM).find(tempByte => {
      if (TEMPERATURE_ENUM[tempByte][this.tempType] === this.currentTemp) {
        this.bleInteractionService.setTemperature(parseInt(tempByte, 10)).then( res => {
          this.getTemperatureFromDevice();
        });
      }
    });
  }

  private getTempLevel(): void {
    if (this.currentTemp < TEMPERATURE_ENUM[36][this.tempType]) {
      this.tempLevel = TEMPERATURE_LEVELS[0];
    } else if (this.currentTemp < TEMPERATURE_ENUM[33][this.tempType]) {
      this.tempLevel = TEMPERATURE_LEVELS[1];
    } else if (this.currentTemp < TEMPERATURE_ENUM[30][this.tempType]) {
      this.tempLevel = TEMPERATURE_LEVELS[2];
    } else if (this.currentTemp < TEMPERATURE_ENUM[27][this.tempType]) {
      this.tempLevel = TEMPERATURE_LEVELS[3];
    } else if (this.currentTemp < TEMPERATURE_ENUM[24][this.tempType]) {
      this.tempLevel = TEMPERATURE_LEVELS[4];
    } else if (this.currentTemp <  TEMPERATURE_ENUM[21][this.tempType]) {
      this.tempLevel = TEMPERATURE_LEVELS[5];
    } else {
      this.tempLevel = TEMPERATURE_LEVELS[6];
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
