import { Injectable } from '@angular/core';
import { BLE_CHARACTERISTICS, BLE_SERVICE } from '../../constants/bluetooth-data';
import {BehaviorSubject} from 'rxjs';
import {HashService} from './hash.service';
import {IDeviceInformation} from '../../interfaces/device-information';
import {SpinnerService} from './spinner.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BleInteractionService {
  public deviceConnection = new BehaviorSubject(false);
  public deviceTempByte: BehaviorSubject<number> = new BehaviorSubject(null);
  public deviceInfo: BehaviorSubject<IDeviceInformation> = new BehaviorSubject(null);
  public deviceName: BehaviorSubject<string> = new BehaviorSubject(null);
  public deviceBatteryLevel: BehaviorSubject<string> = new BehaviorSubject(null);
  public activationMethod: BehaviorSubject<number> = new BehaviorSubject(null);
  public calibrationValue: BehaviorSubject<number> = new BehaviorSubject(null);

  private connectedDevice;
  private bluetoothObj: any = window.navigator.bluetooth;
  private writeCharacteristic: BluetoothRemoteGATTCharacteristic;
  private dummyReadsAttemptsMade = 0;
  private deviceAuthMade = false;
  private readonly AUTH_KEY_SIZE = 16;
  private readonly DUMMY_READS_UNTIL_AUTH_ATTEMPT = 35;

  constructor(private hashService: HashService, private spinnerService: SpinnerService, private router: Router) { }

  public get deviceConnectionStatus(): boolean {
    return this.deviceConnection.value;
  }

  public discoverDevices(): void {
    this.bluetoothObj
      .requestDevice({filters: [{services: [BLE_SERVICE]}]})
      .then(device => {
        device.addEventListener('gattserverdisconnected', this.clearOldDeviceInfo.bind(this));
        // connection to device
        this.spinnerService.showSpinner();
        this.connectedDevice = device;
        return device.gatt.connect();
      })
      .then(server => {
        // connection to device service
        return server.getPrimaryService(BLE_SERVICE);
      })
      .then(service => {
        // getting device characteristics
        this.deviceName.next(service.device.name);
        service.getCharacteristic(BLE_CHARACTERISTICS.WRITE).then(res => {
          this.writeCharacteristic = res;
        });
        return service.getCharacteristic(BLE_CHARACTERISTICS.READ);
      })
      .then(characteristic => {
        // connection for reading characteristic
        return characteristic.startNotifications().then(_ => {
          characteristic.addEventListener('characteristicvaluechanged',
            this.deviceDataHandler.bind(this));
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  public disconnectDevice(): void {
    this.clearOldDeviceInfo();
    this.connectedDevice.gatt.disconnect();
  }

  private clearOldDeviceInfo(): void {
    this.deviceConnection.next(false);
    this.deviceTempByte.next(null);
    this.deviceInfo.next(null);
    this.deviceName.next(null);
    this.deviceBatteryLevel.next(null);
    this.activationMethod.next(null);
    this.writeCharacteristic = null;
    this.dummyReadsAttemptsMade = null;
    this.deviceAuthMade = false;
    this.router.navigate(['devices']);
  }

  private shortHexFrom(argument: number): string{
    const result = '' + argument;
    return result.replace('0x', '');
  }

  private deviceDataHandler(event): void {
    const value = event.target.value;
    const a = [];

    for (let i = 0; i < value.byteLength; i++) {
      a.push('0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
    }

    switch (a[0]) {
      case '0xf0':
        let deviceId = '';
        let deviceSerialNumber = '';

        for (let i = 1; i < 5; i++){
          deviceId += this.shortHexFrom(a[i]);
        }
        for (let i = 10; i < 20; i++){
          deviceSerialNumber += String.fromCharCode(a[i]);
        }

        this.deviceInfo.next({
          deviceSerialNumber,
          deviceId,
          // tslint:disable-next-line:radix
          firmwareVersion:  `${parseInt(a[5])}.${parseInt(a[6])}`
        });

        break;
      case '0xff':
        if (!this.deviceAuthMade) {
          this.dummyReadsAttemptsMade === this.DUMMY_READS_UNTIL_AUTH_ATTEMPT ?
            this.authenticationRequest() :
            this.dummyReadsAttemptsMade++;
        }
        break;
      case '0xf1':
        this.spinnerService.hideSpinner();
        // 0001020304050607080910111213141516171819
        // F102030064000123469801000000000000000000
        // F1                                       - 00 F1
        //   HP                                     - 01 Heater Profile
        //     AM                                   - 02 Activation Method
        //       DL                                 - 03 Device Lock
        //         LB                               - 04 LED Brightness
        //           BL                             - 05 Battery level/4
        if (this.deviceTempByte.getValue() !== value.getUint8(1)) {
          this.deviceTempByte.next(value.getUint8(1));
        }
        this.activationMethod.next(value.getUint8(2));
        this.getBatteryLevel(a[5]);
        if (this.calibrationValue.getValue() !== value.getUint8(12)) {
          this.calibrationValue.next(value.getUint8(12));
        }

        this.deviceConnection.next(true);
        break;

      case '0xf2':
        break;

      case '0xf3':
        const key = this.generateAuthKey(value.buffer);
        this.authenticateConnectedDevice(key);
        break;

      default:
        return;
    }
  }

  private getBatteryLevel(valueByte): void {
    switch (valueByte) {
      case '0x00':
        this.deviceBatteryLevel.next('redline');
        break;
      case '0x01':
        this.deviceBatteryLevel.next('quarter');
        break;
      case '0x02':
        this.deviceBatteryLevel.next('half');
        break;
      case '0x03':
        this.deviceBatteryLevel.next('tree-fourths');
        break;
      case '0x04':
        this.deviceBatteryLevel.next('full');
        break;
      default:
        this.deviceBatteryLevel.next('empty');
    }
  }

  private authenticationRequest(): void {
    //  3 bytes for authentication request
    const authorize = Uint8Array.of(0, 10, 0);

    this.writeCharacteristic.writeValue(authorize).then(() => {
      this.deviceAuthMade = true;
    });
  }

  private authenticateConnectedDevice(key): void {
    this.writeCharacteristic.writeValue(key).then(() => {
      this.spinnerService.hideSpinner();
    });
  }

  private generateAuthKey(buffer): Uint8Array {
    const byteLength = 26;
    const normalUserBytes = [70, 105, 114, 101, 102, 108, 121, 32, 50, 32];
    const responseBytes = new Uint8Array(buffer);
    const keyToHash = new Uint8Array(byteLength);
    let index = 0;

    normalUserBytes.forEach(byte => {
      keyToHash[index] = byte;
      index++;
    });

    for (let i = 0; i < this.AUTH_KEY_SIZE; i++) {
      keyToHash[index] = responseBytes[i + 1];
      index++;
    }

    const hash = this.hashService.hashMD2(keyToHash);

    const key = new Uint8Array(19);
    // set 3 first bytes for authentication
    key[0] = 0;
    key[1] = 10;
    key[2] = 1;

    // fill key array from 3 pos
    index = 3;
    for (let i = 0, len = hash.length; i < len; i += 2) {
      key[index] = (parseInt(hash.substr(i, 2), 16));
      index ++;
    }

    return key;
  }

  public setTemperature(temp: number): Promise<boolean> {
    this.deviceTempByte.next(null);
    const key = new Int8Array(3);

    key[0] = 0;
    key[1] = 2;
    key[2] = temp;

    return this.writeCharacteristic.writeValue(key).then(() => true);
  }

  public setActivationMethod(activationMethod: number): void {
    const key = new Int8Array(3);
    key[0] = 0;
    key[1] = 3;
    key[2] = activationMethod;

    this.writeCharacteristic.writeValueWithoutResponse(key);
  }

  /**
   *
   * @param calibration value between 9 and 31 that maps to 89% to 111%
   */
  public setCalibration(calibration: number): Promise<any> {
    if (calibration >= 9 && calibration <= 31){
      const key = new Int8Array(4);
      key[0] = 0;
      key[1] = 15;
      key[2] = 3;
      key[3] = calibration;

      return this.writeCharacteristic.writeValueWithResponse(key);
    }
  }

}
