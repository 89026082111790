import {Component, OnDestroy, OnInit} from '@angular/core';
import {BleInteractionService} from '../../shared/services/ble-interaction.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  constructor(private bleInteractionService: BleInteractionService, private router: Router) {
  }

  ngOnInit(): void {
  }

  public scanDevices(): void {
    this.bleInteractionService.discoverDevices();
    this.subscriptions.add(this.bleInteractionService.deviceConnection.subscribe(res => {
      if (res) {
        this.router.navigate(['temperature']);
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
