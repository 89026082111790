import {Component, OnDestroy, OnInit} from '@angular/core';
import {BleInteractionService} from '../../shared/services/ble-interaction.service';
import {IDeviceInformation} from '../../interfaces/device-information';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-device-information',
  templateUrl: './device-information.component.html',
  styleUrls: ['./device-information.component.css']
})
export class DeviceInformationComponent implements OnInit, OnDestroy {
  public deviceInfo: IDeviceInformation;
  public deviceName: string;
  private subscriptions: Subscription = new Subscription();

  constructor(private bleInteractionService: BleInteractionService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.bleInteractionService.deviceInfo.subscribe(res => this.deviceInfo = res));
    this.subscriptions.add(this.bleInteractionService.deviceName.subscribe(res => this.deviceName = res));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
