export const CALIBRATION_ENUM = {
  9: 89,
  10: 90,
  11: 91,
  12: 92,
  13: 93,
  14: 94,
  15: 95,
  16: 96,
  17: 97,
  18: 98,
  19: 99,
  20: 100,
  21: 101,
  22: 102,
  23: 103,
  24: 104,
  25: 105,
  26: 106,
  27: 107,
  28: 108,
  29: 109,
  30: 110,
  31: 111
};
