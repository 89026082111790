export const ACTIVATION_METHODS = [
  {
    byteValue: 0,
    name: 'left button'
  },
  {
    byteValue: 1,
    name: 'right button'
  },
  {
    byteValue: 2,
    name: 'both buttons'
  },
  {
    byteValue: 3,
    name: 'either buttons'
  }
];
