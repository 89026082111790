import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public spinnerStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  public showSpinner(): void {
    this.spinnerStatus.next(true);
  }

  public hideSpinner(): void {
    this.spinnerStatus.next(false);
  }
}
