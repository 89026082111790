export const TEMPERATURE_ENUM = {
  46: { f: 200, c: 105 },
  45: { f: 210, c: 110 },
  44: { f: 220, c: 115 },
  43: { f: 230, c: 120 },
  42: { f: 240, c: 125 },
  41: { f: 250, c: 130 },
  40: { f: 260, c: 135 },
  39: { f: 270, c: 140 },
  38: { f: 280, c: 145 },
  37: { f: 290, c: 150 },
  36: { f: 300, c: 155 },
  35: { f: 310, c: 160 },
  34: { f: 320, c: 165 },
  33: { f: 330, c: 170 },
  32: { f: 340, c: 175 },
  31: { f: 350, c: 180 },
  30: { f: 360, c: 185 },
  29: { f: 370, c: 190 },
  28: { f: 380, c: 195 },
  27: { f: 390, c: 200 },
  26: { f: 400, c: 205 },
  25: { f: 410, c: 210 },
  24: { f: 420, c: 215 },
  23: { f: 430, c: 220 },
  22: { f: 440, c: 225 },
  21: { f: 450, c: 230 },
  20: { f: 460, c: 235 },
  19: { f: 470, c: 240 },
  18: { f: 480, c: 245 },
  17: { f: 490, c: 250 },
  16: { f: 500, c: 255 }
};

export const TEMPERATURE_LEVELS = ['ULTRA LOW', 'LOW', 'MEDIUM LOW', 'MEDIUM', 'MEDIUM HIGH', 'HIGH', 'CONCENTRATES'];

