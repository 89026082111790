import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DevicesComponent } from './components/devices/devices.component';
import { TemperatureComponent } from './components/temperature/temperature.component';
import {SliderModule} from 'primeng/slider';
import {FormsModule} from '@angular/forms';
import {SharedModule} from './shared/shared.module';
import { SettingsComponent } from './components/settings/settings.component';
import { DeviceConnectionComponent } from './components/device-connection/device-connection.component';
import { SettingsListComponent } from './components/settings-list/settings-list.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { DeviceInformationComponent } from './components/device-information/device-information.component';
import { ActivationComponent } from './components/activation/activation.component';
import { TempScaleComponent } from './components/temp-scale/temp-scale.component';
import { CalibrationComponent } from './components/calibration/calibration.component';

@NgModule({
  declarations: [
    AppComponent,
    DevicesComponent,
    TemperatureComponent,
    SettingsComponent,
    DeviceConnectionComponent,
    SettingsListComponent,
    DeviceInformationComponent,
    ActivationComponent,
    TempScaleComponent,
    CalibrationComponent
  ],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    AppRoutingModule,
    SliderModule,
    FormsModule,
    SharedModule.forRoot(),
    BrowserAnimationsModule,
    ProgressSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
