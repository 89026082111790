import {Component, OnDestroy, OnInit} from '@angular/core';
import {BleInteractionService} from '../../shared/services/ble-interaction.service';
import {CALIBRATION_ENUM} from '../../constants/calibration';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-calibration',
  templateUrl: './calibration.component.html',
  styleUrls: ['./calibration.component.scss']
})
export class CalibrationComponent implements OnInit, OnDestroy {
  public currentCalibration: number;
  public oldCalibration: number;
  public calibrationEnum = CALIBRATION_ENUM;
  public minValue: number;
  public maxValue: number;
  public successUpdate: boolean;

  private subscriptions: Subscription = new Subscription();

  constructor(private bleInteractionService: BleInteractionService) {
  }

  ngOnInit(): void {
    this.assignValues();
    this.bleInteractionService.calibrationValue.subscribe(res => {
      this.currentCalibration = res;
      this.oldCalibration = res;
    });
  }

  public setCalibration(): void {
    this.bleInteractionService.setCalibration(this.currentCalibration).then(() => {
      this.successUpdate = true;
    });
  }

  public valueChanged(): void {
    this.successUpdate = false;
  }

  private assignValues(): void {
    const calibrationEnumKeys = Object.keys(CALIBRATION_ENUM);
    this.minValue = Number(calibrationEnumKeys[0]);
    this.maxValue = Number(calibrationEnumKeys[calibrationEnumKeys.length - 1]);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
