import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {BleInteractionService} from './ble-interaction.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public bleInteractionService: BleInteractionService, public router: Router) {}
  canActivate(): boolean {
    const deviceConnected = this.bleInteractionService.deviceConnectionStatus;
    if (deviceConnected) {
      return true;
    }
    this.router.navigate(['/devices']);
    return false;
  }
}
