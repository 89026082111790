import {Component, OnDestroy, OnInit} from '@angular/core';
import {TemperatureScaleService} from '../../shared/services/temperature-scale.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-temp-scale',
  templateUrl: './temp-scale.component.html',
  styleUrls: ['./temp-scale.component.css']
})
export class TempScaleComponent implements OnInit, OnDestroy {
  public currentTempScale: string;
  private subscriptions: Subscription = new Subscription();

  constructor(private tempScaleService: TemperatureScaleService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.tempScaleService.tempScale.subscribe(res => this.currentTempScale = res));
  }

  public setTempScale(tempScale: 'f' | 'c'): void {
    this.currentTempScale = tempScale;
    this.tempScaleService.setTempScaleToLocalStorage(this.currentTempScale);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
