<div class="settings">
  <div class="settings-title"><button class="back-btn" appBackButton><img src="assets/images/ic_navigation_arrow_back.png" alt=""></button>Device Info</div>
  <div class="settings-item">
    <div class="settings-name">device name</div>
    <div>{{deviceName}}</div>
  </div>
  <div class="settings-item">
    <div class="settings-name">firmware version</div>
    <div>{{deviceInfo.firmwareVersion}}</div>
  </div>
  <div class="settings-item">
    <div class="settings-name">serial number</div>
    <div>{{deviceInfo.deviceSerialNumber}}</div>
  </div>
</div>
