<div class="settings activation">
  <div class="settings-title"><button class="back-btn" appBackButton><img src="assets/images/ic_navigation_arrow_back.png" alt=""></button>Temperature Scale</div>
  <div class="settings-item active" (click)="setTempScale('f')" [ngClass]="{'selected': currentTempScale === 'f'}">
    <div class="settings-name">fahrenheit</div>
    <div class="settings-icon"><img src="assets/images/check_mark.png" alt=""></div>
  </div>
  <div class="settings-item active" (click)="setTempScale('c')" [ngClass]="{'selected': currentTempScale === 'c'}">
    <div class="settings-name">celsius</div>
    <div class="settings-icon"><img src="assets/images/check_mark.png" alt=""></div>
  </div>
</div>
