<div class="settings-list">
  <div class="settings-item active" [routerLink]="['/settings', {outlets: {settings: 'device-connection'}}]">
    <div class="settings-name">device connection</div>
    <div class="settings-icon"><span>{{deviceName}}</span><img src="assets/images/ic_arrow_right.png" alt=""></div>
  </div>
  <div class="settings-item active" [routerLink]="['/settings', {outlets: {settings: 'device-info'}}]">
    <div class="settings-name" routerLink="awdqwd">device info</div>
    <div class="settings-icon"><img src="assets/images/ic_arrow_right.png" alt=""></div>
  </div>
  <div class="settings-item">
    <div class="settings-name">battery level</div>
    <div class="settings-icon"><img [src]="'assets/images/ic_battery_' + batteryLevel + '.png'" alt=""></div>
  </div>
  <div class="settings-item active"  [routerLink]="['/settings', {outlets: {settings: 'calibration'}}]">
    <div class="settings-name">calibration</div>
    <div class="settings-icon"><span>{{calibrationValue}}%</span><img src="assets/images/ic_arrow_right.png" alt=""></div>
  </div>
  <div class="settings-item active" [routerLink]="['/settings', {outlets: {settings: 'activation'}}]">
    <div class="settings-name">activation</div>
    <div class="settings-icon"><span>{{activationMethod}}</span><img src="assets/images/ic_arrow_right.png" alt=""></div>
  </div>
  <div class="settings-item active" [routerLink]="['/settings', {outlets: {settings: 'temp-scale'}}]">
    <div class="settings-name">temperature scale</div>
    <div class="settings-icon"><span>{{tempScale}}</span><img src="assets/images/ic_arrow_right.png" alt=""></div>
  </div>
</div>
