import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {BleInteractionService} from './services/ble-interaction.service';
import {AuthGuard} from './services/auth-guard.service';
import { BackButtonDirective } from './directives/back-button.directive';
import {HashService} from './services/hash.service';
import {TemperatureScaleService} from './services/temperature-scale.service';



@NgModule({
  declarations: [
    BackButtonDirective
  ],
  exports: [
    BackButtonDirective
  ],
  imports: [
    CommonModule
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        BleInteractionService,
        AuthGuard,
        HashService,
        TemperatureScaleService
      ]
    };
  }
}
