// bluetooth types import, do not delete
/// <reference types="web-bluetooth" />
import { Component } from '@angular/core';
import {SpinnerService} from './shared/services/spinner.service';
import {TemperatureScaleService} from './shared/services/temperature-scale.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public showSpinner = false;

  constructor(private spinnerService: SpinnerService, private tempScaleService: TemperatureScaleService) {
    this.spinnerService.spinnerStatus.subscribe(res => {
      this.showSpinner = res;
    });
    this.tempScaleService.getTempScale();
  }
}
