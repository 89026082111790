<div class="container">
    <div class="header"><span class="logo"><img src="assets/images/svg/header_logo.svg"></span></div>

  <div class="page-container">
    <div [@triggerName]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet" name="settings"></router-outlet>
    </div>

  </div>

  <div class="tabs-container">
    <button class="tabs-button" [routerLink]="'/settings'" [routerLinkActive]="['active-button']">
      <span class="default-icon"><img src="assets/images/ic_settings_default.png"></span>
      <span class="active-icon"><img src="assets/images/ic_settings_selected.png"></span>
    </button>
    <button class="tabs-button" [routerLink]="'/temperature'" [routerLinkActive]="['active-button']">
      <span class="default-icon"><img src="assets/images/ic_zap_default.png"></span>
      <span class="active-icon"><img src="assets/images/ic_zap_selected.png"></span>
    </button>
  </div>

</div>
