import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DevicesComponent} from './components/devices/devices.component';
import {TemperatureComponent} from './components/temperature/temperature.component';
import {AuthGuard} from './shared/services/auth-guard.service';
import {SettingsComponent} from './components/settings/settings.component';
import {DeviceConnectionComponent} from './components/device-connection/device-connection.component';
import {SettingsListComponent} from './components/settings-list/settings-list.component';
import {DeviceInformationComponent} from './components/device-information/device-information.component';
import {ActivationComponent} from './components/activation/activation.component';
import {TempScaleComponent} from './components/temp-scale/temp-scale.component';
import {CalibrationComponent} from './components/calibration/calibration.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'devices', pathMatch: 'full'
  },
  {
    path: 'devices', component: DevicesComponent
  },
  {
    path: 'settings', component: SettingsComponent,  canActivate: [AuthGuard], children: [
      {
        path: 'device-connection', component: DeviceConnectionComponent, outlet: 'settings', data: {animationState: 'Settings'}
      },
      {
        path: 'device-info', component: DeviceInformationComponent, outlet: 'settings', data: {animationState: 'Settings'}
      },
      {
        path: 'activation', component: ActivationComponent, outlet: 'settings', data: {animationState: 'Settings'}
      },
      {
        path: 'calibration', component: CalibrationComponent, outlet: 'settings', data: {animationState: 'Settings'}
      },
      {
        path: 'temp-scale', component: TempScaleComponent, outlet: 'settings', data: {animationState: 'Settings'}
      },
      {
        path: 'list', component: SettingsListComponent, outlet: 'settings' ,  data: {animationState: 'Detail'}
      }
    ]
  },
  {
    path: 'temperature', component: TemperatureComponent, canActivate: [AuthGuard]

  },
  {
    path: '**', component: TemperatureComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
