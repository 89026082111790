import {Component, OnDestroy, OnInit} from '@angular/core';
import {BleInteractionService} from '../../shared/services/ble-interaction.service';
import {Subscription} from 'rxjs';
import {TemperatureScaleService} from '../../shared/services/temperature-scale.service';
import {ACTIVATION_METHODS} from '../../constants/activation-methods';
import {CALIBRATION_ENUM} from '../../constants/calibration';

@Component({
  selector: 'app-settings-list',
  templateUrl: './settings-list.component.html',
  styleUrls: ['./settings-list.component.scss']
})
export class SettingsListComponent implements OnInit, OnDestroy {
  public batteryLevel: string;
  public deviceName: string;
  public tempScale: string;
  public activationMethod: string;
  public calibrationValue: number;
  private subscriptions: Subscription = new Subscription();

  constructor(private bleInteractionService: BleInteractionService, private tempScaleService: TemperatureScaleService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.bleInteractionService.deviceBatteryLevel.subscribe(res => this.batteryLevel = res));
    this.subscriptions.add(this.bleInteractionService.deviceName.subscribe(res => this.deviceName = res));
    this.subscriptions.add(this.bleInteractionService.calibrationValue.subscribe(res => this.calibrationValue = CALIBRATION_ENUM[res]));
    this.subscriptions.add(this.tempScaleService.tempScale.subscribe(res => {
      this.tempScale = res === 'f' ? 'fahrenheit' : 'celsius';
    }));
    this.subscriptions.add(this.bleInteractionService.activationMethod.subscribe(res => {
      ACTIVATION_METHODS.forEach(method => {
        if (method.byteValue === res) {
          this.activationMethod = method.name;
        }
      });
    }));

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
