<div class="container">
  <div class="page-container">
    <div class="temp-container">
      <div class="temp-logo">
        <span class="temp-logo__main"><img src="assets/images/svg/heating_logo.svg"></span>
        <span class="temp-logo__back" [ngStyle]="{'opacity': logoHeating}"><img src="assets/images/logo_glow.jpg"></span>
      </div>

      <div class="temp-value"><span>{{currentTemp}}</span></div>

      <div class="slider-container">
        <p-slider [(ngModel)]="currentTemp"
                  [min]="minTemp"
                  [max]="maxTemp"
                  [step]="valueStep"
                  (onChange)="tempChanged()"
                  (click)="setNewTemperature()"
                  (onSlideEnd)="setNewTemperature()"
                  orientation="vertical">
        </p-slider>
      </div>

      <div class="temp-level">{{this.tempLevel}}</div>
      <div class="temp-background" [ngStyle]="{'transform': 'translateY(' + backHeating + '%)'}">
      </div>
    </div>
  </div>
  <div class="tabs-container">
    <button class="tabs-button" [routerLink]="['/settings', {outlets: {settings: 'list'}}]" [routerLinkActive]="['active-button']">
      <span class="default-icon"><img src="assets/images/ic_settings_default.png"></span>
      <span class="active-icon"><img src="assets/images/ic_settings_selected.png"></span>
    </button>
    <button class="tabs-button" [routerLink]="'/temperature'" [routerLinkActive]="['active-button']">
      <span class="default-icon"><img src="assets/images/ic_zap_default.png"></span>
      <span class="active-icon"><img src="assets/images/ic_zap_selected.png"></span>
    </button>
  </div>
</div>
