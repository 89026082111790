import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemperatureScaleService {
  public tempScale: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() { }

  public getTempScale(): void {
    const cachedTempScale = this.getTempScaleFromLocalStorage();
    this.tempScale.next(cachedTempScale || 'f');
  }

  public setTempScaleToLocalStorage(tempScale: string): void {
    window.localStorage.setItem('tempScale', tempScale);
    this.tempScale.next(tempScale);
  }

  private getTempScaleFromLocalStorage(): string {
    return window.localStorage.getItem('tempScale');
  }
}
