<div class="settings calibration">
  <div class="settings-title"><button class="back-btn" appBackButton><img src="assets/images/ic_navigation_arrow_back.png" alt=""></button>Calibration</div>
  <div class="calibration-container">
    <div class="calibration-value">{{calibrationEnum[currentCalibration]}}%</div>
    <div class="calibration-slider">
          <p-slider [(ngModel)]="currentCalibration"
                    [min]="minValue"
                    [max]="maxValue"
                    [step]="1"
                    (onChange)="valueChanged()">
          </p-slider>
    </div>
    <div class="calibration-btn">
      <button class="gradient-btn small-btn" [ngClass]="{'disabled': currentCalibration == oldCalibration}" (click)="setCalibration()">apply</button>
    </div>
    <div class="calibration-text">Move the slider to the right for richer<br />vapor or to the left for smoother vapor</div>
    <div class="calibration-success" *ngIf="successUpdate">Calibration Updated Successfully</div>
  </div>
</div>
