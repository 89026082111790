import {Component, OnDestroy, OnInit} from '@angular/core';
import {BleInteractionService} from '../../shared/services/ble-interaction.service';
import {Subscription} from 'rxjs';
import {ACTIVATION_METHODS} from '../../constants/activation-methods';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css']
})
export class ActivationComponent implements OnInit, OnDestroy {
  public currentMethod: number;
  public activationMethods;
  private subscriptions: Subscription = new Subscription();

  constructor(private bleInteractionService: BleInteractionService) {
  }

  ngOnInit(): void {
    this.activationMethods = ACTIVATION_METHODS;
    this.subscriptions.add(this.bleInteractionService.activationMethod.subscribe(res => this.currentMethod = res));
  }

  public setActivationVariant(variant: number): void {
    this.bleInteractionService.setActivationMethod(variant);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
