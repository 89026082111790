import {Component, OnDestroy, OnInit} from '@angular/core';
import {BleInteractionService} from '../../shared/services/ble-interaction.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-device-connection',
  templateUrl: './device-connection.component.html',
  styleUrls: ['./device-connection.component.css']
})
export class DeviceConnectionComponent implements OnInit, OnDestroy {
  public deviceName: string;
  private subscriptions = new Subscription();

  constructor(private bleInteractionService: BleInteractionService) { }

  ngOnInit(): void {
    this.subscriptions.add(this.bleInteractionService.deviceName.subscribe(res => {
      this.deviceName = res;
    }));
  }

  public disconnectDevice(): void {
    this.subscriptions.unsubscribe();
    this.bleInteractionService.disconnectDevice();
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
